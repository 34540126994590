var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mt-1" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button-group",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle:collapse-1",
                          arg: "collapse-1",
                        },
                      ],
                      staticClass: "my-2 filter-button-group",
                      attrs: { title: _vm.getDefaultFilterMessage() },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n        Filtra\n      "),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { visible: "", id: "collapse-1" } },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSearch(_vm.filterName)
                    },
                  },
                },
                [
                  _c(
                    "b-card",
                    { staticClass: "filter" },
                    [
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "book_date",
                                label: "Data Registrazione Dal",
                              },
                              model: {
                                value: _vm.filter.byCalendar.from,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "from", $$v)
                                },
                                expression: "filter.byCalendar.from",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: {
                                name: "book_date",
                                label: "Data Registrazione al",
                              },
                              model: {
                                value: _vm.filter.byCalendar.to,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "to", $$v)
                                },
                                expression: "filter.byCalendar.to",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "",
                                label: "Coassicurazione",
                                options: _vm.opt_insurer_participant,
                              },
                              on: { input: _vm.onInputInsurerParticipants },
                              model: {
                                value: _vm.filter.byInsurerParticipant.id,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byInsurerParticipant,
                                    "id",
                                    $$v
                                  )
                                },
                                expression: "filter.byInsurerParticipant.id",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "form-group col-md-3 align-self-end" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle:collapse-1",
                                    arg: "collapse-1",
                                  },
                                ],
                                attrs: {
                                  type: "submit",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                              },
                              [_vm._v("Cerca")]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-reset",
                                attrs: {
                                  type: "button",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onClearFilter(_vm.filterName)
                                  },
                                },
                              },
                              [_vm._v("Reset")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-button-group",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:collapse-1-inner",
                              arg: "collapse-1-inner",
                            },
                          ],
                          staticClass: "my-2 filter-button-group",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "when-open" },
                            [
                              _c("b-icon", {
                                attrs: { icon: "funnel", "font-scale": "1.5" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "when-closed" },
                            [
                              _c("b-icon", {
                                attrs: { icon: "funnel", "font-scale": "1.5" },
                              }),
                            ],
                            1
                          ),
                          _vm._v("\n          Altri Filtri\n        "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          staticClass: "mt-2",
                          attrs: { id: "collapse-1-inner" },
                        },
                        [
                          _c("b-row"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.customFilters.length > 0,
                                  expression: "customFilters.length > 0",
                                },
                              ],
                            },
                            [
                              _c("p", [_vm._v("Attributi personalizzati")]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.customFilters,
                                function (element, index) {
                                  return _c(
                                    "b-row",
                                    { key: index },
                                    [
                                      _c("custom-filter", {
                                        attrs: { element: element },
                                        model: {
                                          value:
                                            _vm.filter.byAttribute[element.key],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filter.byAttribute,
                                              element.key,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filter.byAttribute[element.key]",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("insurer-participants-accounting", {
            ref: _vm.tableRef,
            attrs: {
              fields: _vm.fields,
              repository: _vm.repository,
              resource: _vm.resource,
              filterOn: {
                byRelations: [
                  "byBroker",
                  "byRegistry",
                  "byInsurer",
                  "byInsuranceAncillary",
                  "byVariousAccounting",
                ],
              },
              filterName: _vm.filterName,
              onlyActions: ["infomodal"],
            },
          }),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }